import { HttpInterceptor, HttpErrorResponse, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class InterceptorAutentificacion implements HttpInterceptor {
    constructor(private router: Router) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            console.log('No autorizado... mala cosa. Nos vemos.');
            // navigate /delete cookies or whatever
            this.router.navigate(['public/users/user-login']);
            // if you've caught / handled the error, you don't want to rethrow
            // it unless you also want downstream consumers to have to handle it as well.
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        const sesion = JSON.parse(localStorage.getItem('sessionCondor'));

        // Si la sesión está expirada, redireccionamos al front sin ejecutar el request http.
        if (sesion) {
            const expiracion = new Date(sesion.expiracion);
            const ahora = new Date();
            if (expiracion < ahora) {
                console.log('No autorizado... mala cosa. Nos vemos.');
                localStorage.clear();
                this.router.navigate(['public/users/user-login']);
                return EMPTY;
            } else {
                sesion.expiracion = new Date(ahora.getTime() + (30 * 60000));
                localStorage.setItem('sessionCondor', JSON.stringify(sesion));
            }
        }

        let authReq = req;
        if (sesion) {
            authReq = req.clone({headers: req.headers.set('token', sesion.token)});
        }

        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
        return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
    }
}